
$(function() {
	
	
	
	$("a[href$='#']").click(function(e) {
		e.preventDefault();
		});	
		
		$('.top-slick-slider').slick({
			autoplay:true,
			arrows: false,
			autoplaySpeed:10000,
			asNavFor: '.description-show-slider'
		});	
		
		$('.description-show-slider').slick({
			 autoplay:true,
			 arrows: false,
			 autoplaySpeed:10000,
			 asNavFor: '.top-slick-slider'		  
		});	
		
		$('.top-slick-slider-defaut').slick({
			autoplay:true,
			arrows: false,
			autoplaySpeed:10000	
		});

		$('.show-incoming-slick-slider').slick({
	
	
			autoplay:false,
					arrows: false,
				  
					slidesToScroll:1,
					slidesPerRow: 2,
					   rows: 2,
					responsive: [
					{ breakpoint: 1000, settings: {
					   slidesPerRow: 1,
						rows: 1,
						
						slidesToShow: 2,
						slidesToScroll: 1
					}},
					{ breakpoint: 600, settings: {
					   slidesPerRow: 1,
						rows: 1,
						
						slidesToShow: 1,
						slidesToScroll: 1
					}}
				   
					]
				});
				$('.home-pictures-slick-slider').slick({
					autoplay:false,
					dots: true,
					arrows: false,
				});

				$('.has-submenu > a').each(function(){
					if($(this).attr('href') === '#'){
						$(this).attr('href',$(this).next('ul').children('li:first-child').children('a').attr('href'));
					}
				});

				$(window).scroll(function () {($(this).scrollTop() > 20)?$('#back-top').stop(true).addClass('back-top-show'):$('#back-top').stop(true).removeClass('back-top-show');});$('#back-top').click(function () {$('body, html').stop(true).animate({scrollTop: 0}, 600);});
	
				$(document).foundation();
});